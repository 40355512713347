(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/campaign-handler/actions/constants.js') >= 0) return;  svs.modules.push('/components/lb-utils/campaign-handler/actions/constants.js');
const AVAILABLE_PRODUCTS = [
  'stryktipset',
  'europatipest',
  'powerplay',
  'bomben',
  'matchen',
  'challenge',
  'trav&galopp',
  'topptipset',
  'maltipset'
];
const CAMPAIGN_REWARD = 100;
const CAMPAIGNS = [
  'EM21'
];
const CAMPAIGN_EXPIRATION_DAYS = 60;

const constants = {
  AVAILABLE_PRODUCTS,
  CAMPAIGN_REWARD,
  CAMPAIGNS,
  CAMPAIGN_EXPIRATION_DAYS,
  CAMPAIGN_AVAILABLE: 'CAMPAIGN_AVAILABLE',
  CAMPAIGN_DONE: 'CAMPAIGN_DONE',
  CAMPAIGN_UNAVAILABLE: 'CAMPAIGN_UNAVAILABLE'
};

if (svs.isServer) {
  module.exports = constants;
} else {
  setGlobal('svs.components.lbUtils.campaignHandler.constants', constants);
}


 })(window);