(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/campaign-handler/actions/parse-campaign-status.js') >= 0) return;  svs.modules.push('/components/lb-utils/campaign-handler/actions/parse-campaign-status.js');
'use strict';

const AVAILABLE = 'AVAILABLE';
const EXPIRED = 'EXPIRED';

const defaultErrorObj = { isCampaignAvailable: false, isCampaignUsed: false };

let logger;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('component:campaign-handler');
} else {
  logger = svs.core.log.getLogger('component:campaign-handler');
}
const {
  addDays,
  differenceInSeconds
} = typeof exports === 'object' ? require('date-fns') : dateFns;

const {
  CAMPAIGNS
} = svs.isServer ? require('./constants.js') : svs.components.lbUtils.campaignHandler.constants;

const parseWeirdDate = (weirdDate) => {
  const year = Number(weirdDate.slice(0, 4));
  const month = Number(weirdDate.slice(4, 6)) - 1;
  const day = Number(weirdDate.slice(6, 8));
  const hour = Number(weirdDate.slice(8, 10));
  const minute = Number(weirdDate.slice(10, 12));
  const second = Number(weirdDate.slice(12, 14));

  return new Date(
    year,
    month,
    day,
    hour,
    minute,
    second
  );
};

const parseCampaignStatus = (data) => {
  if (!data || (typeof data !== 'string')) {
    return defaultErrorObj;
  }

  let response;
  try {
    const [campaign, status, ...rest] = data.toUpperCase().split('_');
    if (CAMPAIGNS.includes(campaign)) {
      if (status !== AVAILABLE && status !== EXPIRED) { 
        const [product, drawNumber, boughtFrom, wagerSerial, timestamp] = rest;
        if (timestamp.length === 14) {
          response = {
            weekNumber: Number(status),
            product: product.toLowerCase(),
            drawNumber: Number(drawNumber),
            boughtFrom: boughtFrom,
            wagerSerial: wagerSerial,
            timestamp: parseWeirdDate(timestamp),
            expirationDate: addDays(parseWeirdDate(timestamp), 60),
            isCampaignAvailable: true,
            isCampaignUsed: true
          };
        } else { 
          return defaultErrorObj;
        }
      } else if (status === AVAILABLE) { 
        const [timestamp] = rest;

        response = { campaign: campaign,
          isCampaignAvailable: true,
          isCampaignUsed: false,
          timestamp: new Date(parseWeirdDate(timestamp)),
          expirationDate: addDays(parseWeirdDate(timestamp), 60) };
      }  
      else {
        response = {
          campaign: campaign,
          isCampaignAvailable: false,
          isCampaignUsed: false
        };
      }
    } else {
      response = defaultErrorObj;
    }
  } catch (err) {
    logger.info('formatting campaignstring err', err);
    response = defaultErrorObj;
  }
  return response;
};

const parseCampaignBonusArray = (response) => {
  if (response.error) {
    return { error: response.error };
  }
  const bonusArray = response.bonuses;
  let parsedBonusArray = [];
  if (Array.isArray(bonusArray)) {
    bonusArray.forEach((bonus) => {
      if (bonus.expiry) {
        const isBonusActive = differenceInSeconds(new Date(bonus.expiry), new Date()) >= 0;
        if (isBonusActive && bonus.amount && bonus.releaseType && bonus.bonusGroupId) {
          parsedBonusArray.push(bonus);
        }
      }
    });
  }
  return parsedBonusArray;
};

const parseCampaignBonus = (data) => {
  if (!data) {
    return { error: 'No data recived to parseCampaignBonus' };
  }

  let parsedBonusArray;
  let hasDeposited;
  const { campaignStatus, lastDepositMethod } = data;
  try {
    parsedBonusArray = parseCampaignBonusArray(data);

    if (lastDepositMethod.desktop === null && lastDepositMethod.mobile === null) {
      hasDeposited = false;
    } else {
      hasDeposited = true;
    }
  } catch (err) {
    logger.info('parsing campaign bonus err', err);
  }

  return { parsedBonusArray, hasDeposited, campaignStatus };
};

const toExport = {
  parseCampaignStatus,
  parseCampaignBonus,
  parseCampaignBonusArray
};

if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.lbUtils.campaignHandler.parseCampaignStatus', parseCampaignStatus);
}


 })(window);